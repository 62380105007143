import { Injectable } from "@angular/core";
import { ConfigService } from "../../projects/b2b-ecommerce-lib/src/lib/core/config.service";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppConfigService implements ConfigService {
  constructor() {}
  getItemsUrl(): string {
    return environment.itemsUrl;
  }
  getAccessesUrl(): string {
    return environment.accessesUrl;
  }
  getFilesUrl(): string {
    return environment.filesUrl;
  }
  getAddressesUrl(): string {
    return environment.addressesUrl;
  }
  getPurchasesUrl(): string {
    return environment.purchasesUrl;
  }
  getReviewsUrl(): string {
    return environment.reviewsUrl;
  }
}
