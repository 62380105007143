import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../core/config.service";
import { ResponseBody } from "../models/Entities";


@Injectable({
  providedIn: "root"
})
export class B2bGuestsService {
  
  private config = inject(ConfigService);
  private get baseUrl(): string {
    return this.config.getAccessesUrl() + "/api/v2/guests";
  }

  constructor(private httpClient: HttpClient) { }

  guestLogin(): Observable<ResponseBody<any>> {
    return this.httpClient.get<ResponseBody<any>>(`${this.baseUrl}`);
  }
}
