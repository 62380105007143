import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class B2bHttpInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = JSON.parse(localStorage.getItem("auth-user")!)
    const token = user?.accessToken
  
    let authReq = req;

    if (token) {
       authReq = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${token}`)
      });
    }

    return next.handle(authReq);
  }
}
