import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { B2bLoginService } from './b2b-login.service';
import { B2bToastService } from '../services/b2b-toast.service';

/**
 * This method checks if the user is a guest or if the token does not exist
 * @returns {boolean} false in the case where the user is a guest or if the token does not exist in the session
 */
export const authGuard: CanActivateFn = (route, state) => {
  // const toast = inject(ToastService)
  const toast = inject(B2bToastService)
  const loginService = inject(B2bLoginService)
  const router = inject(Router)

  if (!(!loginService.isLoginGuest() && JSON.parse(localStorage.getItem("auth-user")!) != null)) {
    router.navigate(['/home'])

    if (state.url == "/cart") {
      toast.showErrorToast(toast.routeGuardToastMessages.notLoggedCart[0], toast.routeGuardToastMessages.notLoggedCart[1])
    } else {
      toast.showErrorToast(toast.routeGuardToastMessages.notLoggedGeneric[0], toast.routeGuardToastMessages.notLoggedGeneric[1])
    }

  }

  return (!loginService.isLoginGuest() && JSON.parse(localStorage.getItem("auth-user")!) != null);
};


/**
 * This method checks if the user is a vendor
 * @returns {boolean} true in the case where the user is a vendor
 */
export const vendorGuard: CanActivateFn = (route, state) => {
  // const toast = inject(ToastService)
  const toast = inject(B2bToastService)
  const loginService = inject(B2bLoginService)
  const router = inject(Router)

  if (!(loginService.getDecodedUser().roles == "VENDITORE")) {
    router.navigate(['/home'])
    toast.showErrorToast(toast.routeGuardToastMessages.notVendor[0], toast.routeGuardToastMessages.notVendor[1])
  }

  return (loginService.getDecodedUser().roles == "VENDITORE")
}

