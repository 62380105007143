import { AsyncPipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ToastModule } from "primeng/toast";
import { switchMap } from "rxjs";
import { USER_KEY } from "../../projects/b2b-ecommerce/b2b-login/src/public-api";
import { TokenDTO } from "../../projects/b2b-ecommerce/b2b-models/src/lib/Entities";
import { CategoriesService } from "./services/categories.service";
import { GuestsService } from "./services/guests.service";
import { ToastService } from "./services/toast.service";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { LoginService } from "./services/login.service";
import { I18nService } from "./utils/translate.service";

@Component({
  selector: "app-root",
  standalone: true,
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  imports: [RouterOutlet, TranslateModule, FooterComponent, HeaderComponent, ToastModule, AsyncPipe],
})
export class AppComponent implements OnInit {
  toastBreakpoints = {
    "768px": { width: "80%" },
  };
  constructor(
    public I18NService: I18nService,
    public router: Router,
    private loginService: LoginService,
    private guestsService: GuestsService,
    private toast: ToastService,
    private categoriesService: CategoriesService,
  ) {}

  ngOnInit(): void {
    if (!this.loginService.isLoggedIn()) {
      this.guestLogin();
    } else {
      this.refreshLogin();
    }
  }

  guestLogin() {
    this.guestsService
      .guestLogin()
      .pipe(
        switchMap((response) => {
          const user: TokenDTO = {
            accessToken: response.payload.accessToken,
            refreshToken: response.payload.refreshToken,
          };
          sessionStorage.setItem(USER_KEY, JSON.stringify(user));
          localStorage.setItem(USER_KEY, JSON.stringify(user));
          this.loginService.isLoggedSubject$.next(true);
          return this.categoriesService.getAllCategories();
        }),
      )
      .subscribe({
        next: (response) => {
          console.log(response);
          // this.categoriesService.categories = response
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          if (error.status === 401) {
            localStorage.removeItem("accessToken");
            this.toast.showErrorToast("Error", "Sessione scaduta. Ricaricare l'applicazione");
            this.categoriesService.categories = [];
            this.loginService.resetAuthUser();
            // this.router.navigate(["/home"]).then(() => {
            //   window.location.reload();
            // });
          }
        },
      });
  }

  refreshLogin() {
    const accessToken = this.loginService.getAccessToken();
    const refreshToken = this.loginService.getRefreshToken();
    this.loginService
      .refreshTokens(accessToken, refreshToken)
      .pipe(
        switchMap((response) => {
          this.loginService.isLoggedSubject$.next(true);
          this.loginService.setAuthToken(response.payload.accessToken);
          this.loginService.setUserData(this.loginService.getDecodedUser());

          return this.categoriesService.getAllCategories();
        }),
      )
      .subscribe({
        next: (response) => {
          // this.categoriesService.categories = response
          console.log(response);
        },
        error: (err) => {
          this.toast.showErrorToast(this.toast.appToastMessages.sessionExpired[0], this.toast.appToastMessages.sessionExpired[1]);
          this.loginService.resetAuthUser();
          this.router.navigate(["/home"]).then(() => {
            window.location.reload();
          });
        },
      });
  }

  handleSetNewLanguage(language: string) {
    const newLanguage = language.toLowerCase();
    this.I18NService.switchLang(newLanguage);
  }
}
