import { AmazonLoginProvider, FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from "@abacritt/angularx-social-login";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MessageService } from "primeng/api";
import { environment } from "../environments/environment";
import { routes } from "./app.routes";
import { ConfigService } from "../../projects/b2b-ecommerce-lib/src/public-api";
import { AppConfigService } from "./app-config.service";
import { HttpInterceptorService } from "./services/http-interceptor.service";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(
      // withInterceptors([guestLoginInterceptorInterceptor, errorInterceptor]),
      withInterceptorsFromDi(),
    ),

    {
      provide: "env",
      useValue: environment,
    },
    { provide: ConfigService, useClass: AppConfigService },
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },

    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
    ),
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          //da sostituire provider ID
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider("720216180724-834rgbb6601kjve6ghmkecp9eh4e9s2a.apps.googleusercontent.com"),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("3664299880352438"),
          },
          {
            id: AmazonLoginProvider.PROVIDER_ID,
            provider: new AmazonLoginProvider("amzn1.application-oa2-client.acacaf37d4f34a1c85af254643689cfb"),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
};
