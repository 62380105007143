import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { PageRequestDTO } from "../../../../projects/b2b-ecommerce-lib/src/public-api";
import { CategoryDTO } from "../../../../projects/b2b-ecommerce/b2b-models/src/lib/Entities";
import { UserDTO } from "../../../../projects/b2b-ecommerce/b2b-models/src/lib/EntitiesOld";
import { CategoriesService } from "../../services/categories.service";
import { LoginService } from "../../services/login.service";

@Component({
  selector: "app-footer",
  standalone: true,
  template: `
    <div class="text-center p-2 surface-50">
      <button pButton pRipple type="button" class="text-900 surface-50 surface-border" (click)="scrollTop()">
        {{ "footer.BackToTop" | translate }}
      </button>
    </div>
    <div class="block">
      <div class="grid surface-100 pt-5">
        <div class="col custom-div">
          <span class="text-900 text-lg mb-3">{{ nomeAzienda }}</span>
          <span>
            {{ "footer.BtoCode1" | translate }}
          </span>
          <button pButton pRipple type="button" class="btn-toolset" (click)="goToBtoCodeSite()">
            {{ "footer.ContactUs" | translate }}
          </button>
        </div>
        <div class="col custom-div">
          <span class="text-900 text-lg mb-3">{{ "footer.Products" | translate }}</span>

          @for (categoria of categories; track $index) {
            <a (click)="goToProducts(categoria.uuid)" class="text-800 text-sm cursor-pointer">{{ categoria.name }}</a>
          }
        </div>
        <div class="col custom-div">
          @if (userDTO) {
            <span class="text-900 text-lg mb-3">{{ "footer.YourProfile" | translate }}</span>
            <span>{{ "footer.YesLogin1" | translate }}</span>
            <button pButton pRipple type="button" class="btn-toolset" (click)="goToRegistrati()">
              {{ "footer.MyProfile" | translate }}
            </button>
          } @else {
            <span class="text-900 text-lg mb-3">{{ "footer.LetsStart" | translate }}</span>
            <span>{{ "footer.NoLogin1" | translate }}</span>
            <button pButton pRipple type="button" class="btn-toolset" (click)="goToRegistrati()">
              {{ "footer.NoLogin4" | translate }}
            </button>
          }
        </div>
        <div class="col custom-div">
          <span class="text-900 text-lg mb-3">{{ "footer.OurLocation" | translate }}</span>
          @for (sede of sedi; track sede) {
            <span>{{ sede }}</span>
          }
        </div>
      </div>
    </div>
    <div class="block surface-border flex justify-content-center align-items-center surface-50">
      <img src="../assets/images/sapientialogo.webp" alt="Sapientia Logo" height="100" class="mr-3 cursor-pointer" (click)="goToHome()" />
    </div>
  `,

  styleUrl: "./footer.component.css",
  imports: [TranslateModule, ButtonModule],
})
export class FooterComponent implements OnInit {
  prodotti: string[] = ["e-Books", "Corsi di formazione"];
  sedi: string[] = [
    "Roma, Via Tibullo n.16",
    "Milano, Via Pietrasanta n°12",
    "Torino, Corso Raffaello n°20",
    "Pescara, Via Conte di Ruvo n°74",
    "Teramo, Via E. Fermi n°9 \n San Nicolò a Tordino (TE)",
  ];
  userDTO: UserDTO | null = null;
  categories: CategoryDTO[] = [];
  nomeAzienda = "Gruppo BtoCode";
  categoriesPaginator: PageRequestDTO = {
    page: 0,
    size: 10,
  };

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private categoriesService: CategoriesService,
    private auth: LoginService,
  ) {}

  ngOnInit(): void {
    this.categoriesService.categories$.subscribe((categories) => {
      this.categories = categories;
    });
  }

  /**
   * Method that allows to scroll on top of the web site
   */
  scrollTop() {
    window.scroll({ top: 0, behavior: "smooth" });
  }

  ngAfterViewChecked() {
    this.getUser();
    this.cdRef.detectChanges();
  }

  /**
   * Method for retrieving user's datas.
   */
  getUser() {
    const isLogged = !this.auth.isLoginGuest();
    if (isLogged) {
      this.userDTO = this.auth.getLoggedUser();
    }
  }

  /**
   * Method that take the user to the registration page.
   */
  goToRegistrati() {
    window.scroll(0, 0);
    if (!this.userDTO) {
      this.router.navigate(["/registration"]);
    } else {
      this.router.navigate(["/account"]);
    }
  }

  goToHome() {
    this.router.navigate(["/home"]);
  }

  goToBtoCodeSite() {
    window.location.href = "https://btocode.com/";
  }

  goToProducts(category: string) {
    this.router.navigate(["/category/", category]);
  }
}
