import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {
    // Default theme is Light mode
    const theme: string = localStorage.getItem("currentTheme") || "LIGHT";
    this.setCssTheme(theme);
  }

  setCssTheme(theme: string) {
    let themeLink = this.document.getElementById("app-theme") as HTMLLinkElement;
    const selectedTheme = theme === "LIGHT" ? "lara-light-blue" : "lara-dark-blue";

    if (themeLink) {
      themeLink.href = `${selectedTheme}.css`;
      localStorage.setItem("currentTheme", theme);
    }
  }
}
